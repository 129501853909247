//Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

$dark-bg: #121212;
$dark-color: #cccccc;
$dark-border-color: #434343;
$dark-highlight-bg: #111f33;
$dark-highlight-color: #0d6efd;
$dark-body-alt-bg: #494848;

:root {
  min-width: 320px;
  --bs-body-font-weight: 300;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-weight: 300;
  margin: 1.5rem 0;
  color: $primary;
}
hr {
  opacity: 0.1;
}
.accordion-header {
  margin: 0;
}

[data-bs-theme="dark"] {
  background-color: $dark-bg;
  color: $dark-color;
  border-color: $dark-border-color;
}
[data-bs-theme="dark"] hr {
  border: 0;
  border-top: 1px solid $dark-border-color;
  opacity: 0.7;
}
[data-bs-theme="dark"] .form-control,
[data-bs-theme="dark"] .form-check-input,
[data-bs-theme="dark"] .form-select {
  background-color: $dark-bg;
  color: $dark-color;
  border-color: $dark-border-color;
  &:focus {
    border-color: #224881;
    background-color: $dark-bg;
  }
}

[data-bs-theme="dark"] ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.was-validated .form-control:invalid,
.form-control.is-invalid,.form-control.is-invalid:focus,
.was-validated .form-select:invalid,
.form-select.is-invalid,.form-control.is-invalid:focus {
  border-color: #dc3545;
  padding: 0.375rem 0.75rem;
  background-image: none;
}

.was-validated .form-control:valid,
.form-control.is-valid, .form-control.is-valid:focus,
.was-validated .form-select:valid,
.form-select.is-valid, .form-control.is-valid:focus {
  border-color: #198754;
  padding: 0.375rem 0.75rem;
  background-image: none;
}

[data-bs-theme="dark"] .table {
  --bs-table-border-color: $dark-border-color;
  --bs-table-striped-bg: $dark-body-alt-bg;
  --bs-table-striped-color: $dark-highlight-color;
  --bs-table-active-color: $dark-color;
  --bs-table-active-bg: $dark-border-color;
  --bs-table-hover-color: $dark-highlight-color;
  --bs-table-hover-bg: $dark-highlight-bg;
  color: $dark-color;
  border-color: $dark-border-color;
}
[data-bs-theme="dark"] .table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}
[data-bs-theme="dark"] .accordion-item,
[data-bs-theme="dark"] .accordion-button {
  background-color: $dark-bg;
  color: $dark-color;
  border-color: $dark-border-color;
}
[data-bs-theme="dark"] .accordion-button:not(.collapsed) {
  color: $dark-highlight-color;
  background-color: $dark-highlight-bg;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 $dark-border-color;
}
[data-bs-theme="dark"] .accordion-button.collapsed::after {
  background-image: escape-svg(
    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$dark-border-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>")
  );
}

[data-bs-theme="dark"] #dark-mode.form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
  //  &:focus {
  //    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
  //  }
  &:checked {
    background-color: $primary;
    //border-color: tint-color($dark-color), 80%);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e");
  }
}

#dark-mode.form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
  //   margin: 0 0 0 0.5rem;
  //   float: right;
}

[data-bs-theme="dark"] .input-group-text {
  background-color: $dark-highlight-bg;
  color: $dark-color;
  border-color: $dark-border-color;

}

[data-bs-theme="dark"] .pagination .page-item .page-link {
  background-color: $dark-bg;
  color: $dark-color;
  border-color: $dark-border-color;
}

[data-bs-theme="dark"] .pagination .page-item.active .page-link {
  color: #fff;
  background-color: $dark-highlight-color;
  border-color: $dark-highlight-color;
}

footer .navbar, footer .navbar a {
  color: #999;
  text-decoration: none;
}

footer .navbar a:hover {
  color: rgba(255, 255, 255, 0.75);;
}

.fit {
  width: 1px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
